import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './admin-dashboard.scss';
import { SpinnerDiamond } from 'spinners-react';
import { useNavigate } from 'react-router-dom';
import auth from '../../firebase/init';
import { authActions } from '../../redux/auth-slice';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import TabNavItem from '../../components/tabs/tabNavItem/tabNavItem';
import TabWrapper from '../../components/tabs/tabWrapper/tabWrapper';
import ClientsTab from '../../components/adminTabs/clientsTab/clientsTab';
import DNCTab from '../../components/adminTabs/dncTab/dncTab';
import { showErrorToast } from '../../utils/toast';
import fuelStationIdSlice, {
  fuelStationIdActions,
} from '../../redux/fuelStationIdSlice';
import { clientTypeActions } from '../../redux/clientType-slice';
import BillingTab from '../../components/adminTabs/billingTab/billingTab';
import SequenceLinkingTab from '../../components/adminTabs/sequenceLinkingTab/sequenceLinkingTab';
import pattern from '../../assets/pattern-v3.svg';
import { PopupButton } from '@typeform/embed-react';
import feedback from '../../assets/sideBar/feedback.png';

interface ISelector {
  type: string;
  payload: [];
}

interface IFuelStationIdSlice {
  fuelId: ISelector;
}

interface IClientTypeSlice {
  clientType: ISelector;
}

interface IStripeId {
  type: string;
  payload: string;
}

interface IAuthSlice {
  stripeId: IStripeId;
}

interface IStore {
  fuelStationId: IFuelStationIdSlice;
  clientType: IClientTypeSlice;
  auth: IAuthSlice;
}

const AdminDashboard = () => {
  const fuelStationRedux = useSelector(
    (state: IStore) => state.fuelStationId.fuelId
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const apiEndpoint = process.env.REACT_APP_API_URL;

  const [clients, setClients] = useState([]);
  const [onboardingClients, setOnboardingClients] = useState([]);
  const [allSaasClients, setAllSaasClients] = useState([]);
  const [pausedClients, setPausedClients] = useState([]);
  const [offboardingClients, setOffboardingClients] = useState([]);
  const [blacklistLogs, setBlacklistLogs] = useState([]);
  const [activeTab, setActiveTab] = useState('tab1');
  const [loading, setLoading] = useState(true);
  const [stripeId, setStripeId] = useState('');
  const [billingData, setBillingData] = useState(null);
  const [sequenceLinkingData, setSequenceLinkingData] = useState(null);
  const [totalClients, setTotalClients] = useState(null);
  const [reloadClients, setReloadClients] = useState(false);
  const [clientPaused, setClientPaused] = useState(false);

  // Function to update the active tab from client tab
  const handleSetActiveTab = () => {
    setActiveTab('tab4');
  };

  const openStripeCustomerPortal = () => {
    const data = {
      customer_id: stripeId,
      customer_url: window.location.href,
    };
    auth.onAuthStateChanged((user) => {
      user
        .getIdToken(/* forceRefresh */ true)
        .then(function (idToken) {
          axios({
            method: 'POST',
            url: apiEndpoint + 'api/billing',
            headers: { Authorization: 'Bearer ' + idToken },
            data,
          }).then((response) => {
            window.open(response.data.data);
          });
        })
        .catch(function (error) {
          // Handle error
        });
    });
  };

  useEffect(() => {
    if (Cookies.get('parent') === '18') {
      Cookies.set('clientType', 'dark', { expires: 1 });
      const headers = {
        Authorization:
          'Bearer secret_sFkEd8tPFa7rHFCHvdSeRBFiwRz4r9Vgt5GOkzDPPwW',
        'Notion-Version': '2022-06-28',
        'Content-Type': 'application/json',
      };

      axios({
        method: 'POST',
        url: 'https://ftf-cors-proxy.herokuapp.com/https://api.notion.com/v1/databases/b77646ab032749ffbaa99df6d87fe29f/query',
        headers: headers,
      })
        .then((response) => {
          setLoading(false);
          const tempActiveClients: React.SetStateAction<any[]> = [];
          const tempOnboardingClients: React.SetStateAction<any[]> = [];
          const tempPausedClients: React.SetStateAction<any[]> = [];
          const tempOffboardingClients: React.SetStateAction<any[]> = [];
          response.data.results.map((client: any) => {
            if (
              client.properties.Status.select &&
              client.properties['Package '].multi_select[0]?.name !=
                'Control Room'
            ) {
              switch (client.properties.Status.select.name) {
                case 'Active':
                  if (client.properties.Client.title[0]) {
                    tempActiveClients.push(
                      client.properties.Client.title[0].plain_text
                    );
                  }
                  break;
                case 'Onboarding':
                  if (client.properties.Client.title[0]) {
                    tempOnboardingClients.push(
                      client.properties.Client.title[0].plain_text
                    );
                  }
                  break;
                case 'Paused':
                  if (client.properties.Client.title[0]) {
                    tempPausedClients.push(
                      client.properties.Client.title[0].plain_text
                    );
                  }
                  break;
                case 'Offboarding':
                  if (client.properties.Client.title[0]) {
                    tempOffboardingClients.push(
                      client.properties.Client.title[0].plain_text
                    );
                  }
                  break;
                default:
                // Do nothing
              }
            }
          });
          tempActiveClients.sort();
          tempOnboardingClients.sort();
          setClients(tempActiveClients);
          setOnboardingClients(tempOnboardingClients);
          setPausedClients(tempPausedClients);
          setOffboardingClients(tempOffboardingClients);
          getDNCLogs();
        })
        .catch((error) => {
          setLoading(false);
        });

      auth.onAuthStateChanged((user) => {
        user
          .getIdToken(/* forceRefresh */ true)
          .then(function (idToken) {
            axios({
              method: 'GET',
              url: apiEndpoint + 'api/clients/' + Cookies.get('parent'),
              headers: { Authorization: 'Bearer ' + idToken },
            })
              .then((response) => {
                // console.log(response.data.data.children);
                const data = response.data.data.children;
                setStripeId(response.data.data.stripe_id);
                const sassChildren: string[] = [];
                data?.map((client: any) => {
                  sassChildren.push(client);
                });
                setLoading(false);
                setAllSaasClients(sassChildren);
                dispatch(fuelStationIdActions.setFuelId(data));
              })
              .catch((error) => {
                // console.log(error);
              });
          })
          .catch(function (error) {
            // Handle error
          });
      });
    } else {
      auth.onAuthStateChanged((user) => {
        user
          .getIdToken(/* forceRefresh */ true)
          .then(function (idToken) {
            //ClientType
            axios({
              method: 'GET',
              url: apiEndpoint + 'api/clients/' + Cookies.get('parent'),
              headers: { Authorization: 'Bearer ' + idToken },
            })
              .then((response) => {
                const data = response.data.data.children;
                setStripeId(response.data.data.stripe_id);
                const sassChildren: string[] = [];
                data?.map((client: any) => {
                  sassChildren.push(client);
                });
                setLoading(false);
                setAllSaasClients(sassChildren);
                dispatch(fuelStationIdActions.setFuelId(data));
              })
              .catch((error) => {
                // console.log(error);
              });
          })
          .catch(function (error) {
            // Handle error
          });
      });
    }
  }, [reloadClients]);

  // The function to be called in child component to update reloadClients
  const updateReloadClients = () => {
    setReloadClients((prev) => !prev);
  };

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      user
        .getIdToken(/* forceRefresh */ true)
        .then(function (idToken) {
          axios({
            method: 'GET',
            url: apiEndpoint + 'api/subscription/' + Cookies.get('parent'),
            headers: { Authorization: 'Bearer ' + idToken },
          })
            .then((response) => {
              const data = response.data.data;
              setBillingData(data); //Data for billing page
              setTotalClients(data.total_clients);
              if (data.status === 'paused') {
                setClientPaused(true);
                setActiveTab('tab3');
              }
            })
            .catch((error) => {
              // console.log(error);
            });

          axios({
            method: 'GET',
            url: apiEndpoint + 'api/campaign-linking/' + Cookies.get('parent'),
            headers: { Authorization: 'Bearer ' + idToken },
          })
            .then((response) => {
              setSequenceLinkingData(response.data.data.smartlead);
            })
            .catch(() => {
              showErrorToast('Failed to load campaigns');
            });
        })

        .catch(function (error) {
          // Handle error
        });
    });
  }, []);

  const logout = () => {
    auth.signOut();
    Cookies.remove('loggedIn');
    Cookies.remove('isAdmin');
    Cookies.remove('clientType');
    dispatch(authActions.logout());
    navigate('/');
  };

  const getDNCLogs = () => {
    auth.onAuthStateChanged((user) => {
      user
        .getIdToken(/* forceRefresh */ true)
        .then(function (idToken) {
          axios({
            method: 'GET',
            url: apiEndpoint + 'api/blacklist-logs',
            headers: { Authorization: 'Bearer ' + idToken },
          })
            .then((response) => {
              setBlacklistLogs(response.data.data);
            })
            .catch(() => {
              showErrorToast('Failed to fetch logs');
            });
        })
        .catch(function (error) {
          // Handle error
        });
    });
  };

  return (
    <div className="admin-dashboard-page-container">
      <div
        className={` admin-dashboard-cover-image`}
        style={
          Cookies.get('clientType') === 'SaaS'
            ? null
            : {
                backgroundImage:
                  'url(https://fueltofly.ams3.digitaloceanspaces.com/client-portal-images/client-cover.jpeg)',
              }
        }
      >
        {/* {Cookies.get('clientType') === 'SaaS' ? (
          <img className="admin-saas-image" src={pattern} alt="" />
        ) : null} */}
        <div className="admin-header-container">
          <p className="admin-header">Admin Dashboard</p>
        </div>
        <div className="top-right-elements-container">
          <button className="logout-button" onClick={() => logout()}>
            Logout
          </button>
        </div>
      </div>

      <div className="admin-dashboard-body-container">
        <div className="admin-tabs-container">
          <ul className="nav">
            {clientPaused ? null : (
              <TabNavItem
                id="tab1"
                title="Client List"
                activeTab={activeTab}
                setActiveTab={setActiveTab}
              />
            )}

            {Cookies.get('parent') === '18' ? (
              <TabNavItem
                id="tab2"
                title="DNC Logs"
                activeTab={activeTab}
                setActiveTab={setActiveTab}
              />
            ) : null}

            <TabNavItem
              id="tab3"
              title="Billing"
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
            {clientPaused ? null : (
              <TabNavItem
                id="tab4"
                title="Campaign Linking"
                activeTab={activeTab}
                setActiveTab={setActiveTab}
              />
            )}
          </ul>

          <div className="tabs-body">
            <TabWrapper id="tab1" activeTab={activeTab}>
              <ClientsTab
                loading={loading}
                activeClients={clients}
                onboardingClients={onboardingClients}
                pausedClients={pausedClients}
                offboardingClients={offboardingClients}
                allSaasClients={allSaasClients}
                totalClients={totalClients}
                updateReloadClients={updateReloadClients}
                setActiveTab={handleSetActiveTab}
              />
            </TabWrapper>
            {Cookies.get('parent') === '18' ? (
              <TabWrapper id="tab2" activeTab={activeTab}>
                <DNCTab blacklistLogs={blacklistLogs} />
              </TabWrapper>
            ) : null}

            <TabWrapper id="tab3" activeTab={activeTab}>
              <>
                {clientPaused ? null : <BillingTab billingData={billingData} />}
                {clientPaused ? (
                  <p style={{ margin: '3rem 0 -2rem 0' }}>
                    You’ve reached the end of your 7 day free trial. Please
                    update your billing details below to continue using the
                    Ctrlroom.
                  </p>
                ) : null}
                <button
                  type="submit"
                  className="cursor billing-portal-button"
                  onClick={() => openStripeCustomerPortal()}
                >
                  Open Billing Portal
                </button>
              </>
            </TabWrapper>
            <TabWrapper id="tab4" activeTab={activeTab}>
              <SequenceLinkingTab
                clientList={allSaasClients}
                sequenceNameList={sequenceLinkingData}
              />
            </TabWrapper>
          </div>
        </div>

        <div className="email-support">
          For support or client deletion email
          <a href="mailto:support@ctrlroom.ai">support@ctrlroom.ai</a> or fill
          out the following{' '}
          <PopupButton id="lpekvXpT">support form</PopupButton>
        </div>
        <div className="slack-channel">
          You can also join our slack channel{' '}
          <a
            href="https://join.slack.com/t/ctrlroomworkspace/shared_invite/zt-2n24el0w7-Y14AEqbkyoK5dK9PzI7Dhg"
            target="_blank"
            rel="noopener noreferrer"
          >
            here
          </a>
        </div>
        {loading || !fuelStationRedux ? (
          <SpinnerDiamond
            size={80}
            thickness={100}
            speed={100}
            color="#51abcb"
            secondaryColor="rgba(0, 0, 0, 0.44)"
            className="spinner"
          />
        ) : null}
      </div>
    </div>
  );
};

export default AdminDashboard;
