// Fundamentals
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import auth from '../../firebase/init';

// Styles
import './topBar.scss';

// Assets
import search from '../../assets/topBar/search-icon.png';
import notifications from '../../assets/topBar/notifications-icon.png';

//Images
import companyLogoDefault from '../../assets/sideBar/default-company-logo.svg';

// Redux
import { contactDataActions } from '../../redux/contact-data-slice';
import { clientActions } from '../../redux/client-slice';
import { searchActions } from '../../redux/searchSlice';
import { useDispatch, useSelector } from 'react-redux';
import { sequenceActions } from '../../redux/sequenceIdsSlice';

// Components
import SearchResults from '../../components/searchResults/searchResults';

interface ITopBar {
  isAdmin: boolean;
  globalSearch: boolean;
  setGlobalSearch: any;
}

interface IClientDataSelector {
  payload: '';
}

interface IClientDataSlice {
  imageUrl: IClientDataSelector;
}

interface IsequenceId {
  type: any;
  payload: string[];
}

interface Isequence {
  sequenceIds: IsequenceId;
}

interface IStore {
  client: IClientDataSlice;
  sequenceId: Isequence;
}

const NotificationsButton = () => {
  return (
    <div className="topbar-container__right-elements-container__notifications-button curved-button">
      <img src={notifications} alt="notification" />
    </div>
  );
};

const TopBar = ({ isAdmin, setGlobalSearch, globalSearch }: ITopBar) => {
  const apiEndpoint = process.env.REACT_APP_API_URL;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [isSearchbarDropdownOpen, setIsSearchbarDropdownOpen] = useState(false);
  const [filteredNames, setFilteredNames] = useState([]);
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [loadingContacts, setLoadingContacts] = useState(false);
  const [filtersCleared, setFiltersCleared] = useState(false);
  const initialRender = useRef(true);
  const firstRender = useRef(true);

  const imageUrlRedux = useSelector((state: IStore) => state.client.imageUrl);
  const allSequenceIds = useSelector(
    (state: IStore) => state.sequenceId.sequenceIds
  );

  const inputRef = useRef(null);

  useEffect(() => {
    setLoadingContacts(true);

    auth.onAuthStateChanged((user) => {
      user
        .getIdToken(/* forceRefresh */ true)
        .then(function (idToken) {
          // axios({
          //   method: 'GET',
          //   // url:
          //   //   apiEndpoint + 'api/initial-contacts/' +
          //   //   Cookies.get('displayName'),
          //   url:
          //     apiEndpoint + 'api/smartlead-contact/' +
          //     Cookies.get('fuelStationId'),
          //   headers: { Authorization: 'Bearer ' + idToken },
          // })
          //   .then((response) => {
          //     setLoadingContacts(false);
          //     setRowData(response.data.data.contacts);
          //     dispatch(
          //       contactDataActions.setClientContactStages(
          //         response.data.data.stages
          //       )
          //     );
          //     dispatch(
          //       searchActions.setInterestedContactsData(
          //         response.data.data.contacts
          //       )
          //     );
          //   })
          //   .catch(() => {
          //     setLoadingContacts(false);
          //   });

          axios({
            method: 'GET',
            // url:
            //   apiEndpoint + 'api/contact/' +
            //   Cookies.get('displayName'),
            url:
              apiEndpoint +
              'api/smartlead-contact/' +
              Cookies.get('fuelStationId'),
            headers: { Authorization: 'Bearer ' + idToken },
          })
            .then((response) => {
              setLoadingContacts(false);
              setRowData(response.data.data.contacts);
              dispatch(
                contactDataActions.setClientContactStages(
                  response.data.data.stages
                )
              );
              dispatch(
                searchActions.setContactsData(response.data.data.contacts)
              );
            })
            .catch(() => {
              setLoadingContacts(false);
            });

          axios({
            method: 'GET',
            url: apiEndpoint + 'api/client/' + Cookies.get('fuelStationId'),
            headers: { Authorization: 'Bearer ' + idToken },
          })
            .then((response) => {
              if (response.data.data.copies.length > 0) {
                dispatch(
                  clientActions.setImageUrl(
                    response.data.data.copies[0].client__image_url
                  )
                );
              }
            })
            .catch(() => {
              // setError(false);
            });
        })
        .catch(function (error) {
          // Handle error
        });
    });
  }, []);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return; // Skip the effect on initial render
    }
    const uniqueCampaignIds = Array.from(
      new Set(rowData.map((item: any) => String(item.campaign_id)))
    );
    dispatch(sequenceActions.setSequenceIds(uniqueCampaignIds));
  }, [rowData]);

  const searchContacts = (search: string) => {
    if (search.length == 0) {
      setFilteredNames([]);
    } else {
      const filtered = rowData.filter((item) => {
        const fullName = item.First_Name + ' ' + item.Last_Name;
        if (
          fullName.toLowerCase().includes(search.toLowerCase()) ||
          item.Email.toLowerCase().includes(search.toLowerCase())
        ) {
          return item;
        }
      });
      setFilteredNames(filtered);
    }
  };

  const searchCompanies = (search: string) => {
    if (search.length == 0) {
      setFilteredCompanies([]);
    } else {
      const filtered = rowData.filter((item) =>
        item.Company.toLowerCase().includes(search.toLowerCase())
      );
      setFilteredCompanies(filtered);
    }
  };

  const handleSearch = (search: string) => {
    setSearchText(search);
    searchContacts(search);
    searchCompanies(search);
  };

  const handleFocus = (search: string) => {
    setSearchText(search);
    if (search.length == 0) {
      setFilteredNames([]);
      setFilteredCompanies([]);
    }
    setIsSearchbarDropdownOpen(true);
  };

  const handleContactClick = (firstName: string, lastName: string) => {
    dispatch(searchActions.setSearchType('contact'));
    dispatch(searchActions.setFirstNameSearchValue(firstName));
    dispatch(searchActions.setLastNameSearchValue(lastName));
    dispatch(searchActions.setContactSearch(true));
    setIsSearchbarDropdownOpen(false);
    const path = location.pathname;
    if (isAdmin) {
      if (path.substring(0, path.lastIndexOf('/')) != '/admin/contacts') {
        navigate('/admin/contacts/' + Cookies.get('displayName'));
      }
    } else {
      if (path.substring(0, path.lastIndexOf('/')) != '/contacts') {
        navigate('/contacts/' + Cookies.get('displayName'));
      }
    }
  };

  const handleCompanyClick = (companyName: string) => {
    dispatch(searchActions.setSearchType('company'));
    dispatch(searchActions.setCompanySearchValue(companyName));
    dispatch(searchActions.setContactSearch(true));
    setIsSearchbarDropdownOpen(false);
    const path = location.pathname;
    if (isAdmin) {
      if (path.substring(0, path.lastIndexOf('/')) != '/admin/contacts') {
        navigate('/admin/contacts/' + Cookies.get('displayName'));
      }
    } else {
      if (path.substring(0, path.lastIndexOf('/')) != '/contacts') {
        navigate('/contacts/' + Cookies.get('displayName'));
      }
    }
  };

  const toggleModalVisibility = () => {
    setIsSearchbarDropdownOpen(!isSearchbarDropdownOpen);
  };

  const handleProfileClick = () => {
    if (isAdmin) {
      navigate('/admin/settings/' + Cookies.get('displayName'));
    } else {
      navigate('/settings/' + Cookies.get('displayName'));
    }
  };

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      return; // Skip the effect on initial render
    } //Each time the global search is updated in the contact page (clear search is clicked) it will also clear the search in the top bar
    clearSearch();
  }, [globalSearch]);

  const clearSearchDual = () => {
    clearSearch();
    setGlobalSearch((prevState: boolean) => !prevState);
  };

  const clearSearch = () => {
    setSearchText('');
    setFilteredCompanies([]);
    setFilteredNames([]);
    if (inputRef.current) {
      inputRef.current.value = ''; // Clearing the input field
    }
  };

  return (
    <div className="topbar-container">
      <SearchResults
        searchText={searchText}
        visible={isSearchbarDropdownOpen}
        filteredNames={filteredNames}
        filteredCompanies={filteredCompanies}
        loadingContacts={loadingContacts}
        handleContactClick={handleContactClick}
        handleCompanyClick={handleCompanyClick}
        toggleModalVisibility={toggleModalVisibility}
      />
      <div
        className={[
          'topbar-container__searchbar-container',
          'topbar-container__focused-searchbar',
        ].join(' ')}
      >
        <div className="topbar-container__searchbar-container__top-row">
          <img className="icon-with-right-margin" src={search} alt="search" />
          <input
            type="text"
            placeholder="Search for contacts"
            onChange={(e) => handleSearch(e.target.value)}
            onFocus={(e) => handleFocus(e.target.value)}
            ref={inputRef}
          />
          {searchText.length > 0 ? (
            <button onClick={clearSearchDual}>x</button>
          ) : null}
        </div>
      </div>

      <div className="topbar-container__right-elements-container">
        {/* <NotificationsButton /> */}
        <div
          className="topbar-container__right-elements-container__logo"
          onClick={handleProfileClick}
        >
          <img
            src={
              imageUrlRedux.payload &&
              imageUrlRedux.payload !=
                'https://fueltofly.ams3.digitaloceanspaces.com/client_profile_images/profile.png'
                ? imageUrlRedux.payload
                : companyLogoDefault
            }
            alt="logo"
          />
        </div>
      </div>
    </div>
  );
};

export default TopBar;
