import { createSlice } from '@reduxjs/toolkit';

const sequenceIdsSlice = createSlice({
  name: 'sequenceId',
  initialState: {
    sequenceIds: null,
  },
  reducers: {
    setSequenceIds(state, payload) {
      state.sequenceIds = payload;
    },
  },
});

export const sequenceActions = sequenceIdsSlice.actions;

export default sequenceIdsSlice;
